<template>
  <v-container >
    <v-row justify="space-around">
      <!-- header -->
      <v-col cols="12">
        <v-card-title class="headline font-weight-light text-center">
          Пожарно-технический минимум для руководителей и ответственных за пожарную безопасность <br> в учреждениях
        </v-card-title>
      </v-col>
      <!-- /header -->
      <v-col
      cols="12">
        <v-card>
          <v-tabs active-class="ptm-dash-tab" color="red" v-model="tab">
            <v-tab>О курсе</v-tab>
            <v-tab>Разделы для изучения</v-tab>
            <v-tab>Ресурсы</v-tab>
            <v-tab>Результаты</v-tab>
            <v-spacer></v-spacer>
            <v-btn
            color="red"
            dark
            height="100%"
            tile
            to="/theme1"
            >
            <v-icon class="mr-3">mdi-school</v-icon>
              перейти к изучению
            </v-btn>
          </v-tabs>

        </v-card>
      </v-col>
      <v-col
      cols="12">
        <v-card class="pa-5">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-card-title class="font-weight-medium mb-0">
                      О курсе:
                    </v-card-title>
                    <v-card-text class="title font-weight-light mb-0">
                      Пожарно-технический минимум для руководителей и ответственных за пожарную безопасность в учреждениях
                    </v-card-text>
                    <v-card-title class="font-weight-medium mb-0">
                      Задачи:
                    </v-card-title>
                    <v-card-text class="title font-weight-light pb-0 ">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      Снижение рисков возникновения очагов возгорания;
                    </v-card-text>
                    <v-card-text class="title font-weight-light pb-0 ">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      Соблюдение противопожарного режима;
                    </v-card-text>
                    <v-card-text class="title font-weight-light pb-0 ">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      Формирование практических навыков по предотвращение возгораний, спасению людей и имущества организации;
                    </v-card-text>
                  </v-col>
                  <v-col cols="6">
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="150"
                    flat
                    >

                      <v-img
                        lazy-src="img/material/stopwatch.svg"
                        max-width="80"
                        src="img/material/stopwatch.svg"
                        class="ma-5"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="subtitle-1 font-weight-light">
                        Рекомендуемое время изучения: 4 часа
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="150"
                    tag="a"
                    flat
                    href="https:umcmrg.ru"
                    >

                      <v-img
                        lazy-src="img/material/internet.svg"
                        max-width="80"
                        src="img/material/internet.svg"
                        class="ma-5"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="subtitle-1 font-weight-light">
                        Сайт Научно-образовательного центра
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-tabs v-model="tabsThemes" grow color="red" class="">
                <v-tab>Раздел №1</v-tab>
                <v-tab>Раздел №2</v-tab>
                <v-tab>Раздел №3</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabsThemes">
                <v-tab-item>
                  <v-card
                    class="pa-2 d-flex  justify-center align-center mt-10"
                    flat
                    >
                    <div class="d-flex flex-column justify-center align-center">
                      <v-icon color="red" size="100">mdi-school</v-icon>
                      <v-card-text class="title text-center">
                        Законодательная база в области пожарной безопасности
                      </v-card-text>
                    </div>
                    </v-card>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-card
                          class="pa-2 d-flex justify-center align-center"
                          full-width
                          height="200"
                          to="/theme1"
                          :disabled="theme1Status"
                          hover
                          >
                            <v-img
                              lazy-src="img/material/balance.svg"
                              max-width="80"
                              src="img/material/balance.svg"
                              class="ma-1"
                            ></v-img>
                            <v-spacer></v-spacer>
                            <v-card-text class="title font-weight-light">
                              Основные нормативные документы, регламентирующие требования ПБ
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- <v-card-text class="title font-weight-light">
                      Темы раздела:
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" size="30">mdi-check</v-icon>
                      Нормативные документы, регламентирующие требования ПБ
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" size="30">mdi-check</v-icon>
                      Инструкция о мерах пожарной безопасности
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" size="30">mdi-check</v-icon>
                      Система обеспечения пожарной безопасности
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" size="30">mdi-check</v-icon>
                      Права и обязанности граждан и руководителей
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" size="30">mdi-check</v-icon>
                      Ответственность за обеспечение ПБ
                    </v-card-text> -->
                </v-tab-item>
                <v-tab-item>
                   <v-card
                    class="pa-2 d-flex justify-center align-center mt-10"
                    flat
                    >
                    <div class="d-flex flex-column justify-center align-center">
                      <v-icon color="red" size="100">mdi-school</v-icon>
                       <v-card-text class="title text-center">
                        Организационные мероприятия по обеспечению ПБ в зданиях и помещениях с массовым пребыванием людей
                      </v-card-text>
                    </div>
                    </v-card>
                     <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-card
                          class="pa-2 d-flex justify-center align-center"
                          full-width
                          height="200"
                          to="/theme2"
                          :disabled="theme2Status"
                          hover
                          >
                            <v-img
                              lazy-src="img/material/human.svg"
                              max-width="80"
                              src="img/material/human.svg"
                              class="ma-1"
                            ></v-img>
                            <v-spacer></v-spacer>
                            <v-card-text class="subtitle-1 font-weight-light">
                              Требования по обеспечению ПБ в зданиях и помещениях с массовым пребыванием людей
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="6">
                          <v-card
                          class="pa-2 d-flex justify-center align-center"
                          full-width
                          height="200"
                          to="/theme3"
                          :disabled="theme3Status"
                          hover
                          >
                            <v-img
                              lazy-src="img/material/hotel-2.svg"
                              max-width="80"
                              src="img/material/hotel-2.svg"
                              class="ma-1"
                            ></v-img>
                            <v-spacer></v-spacer>
                            <v-card-text class="subtitle-1 font-weight-light">
                              Меры ПБ в зданиях и помещениях с массовым скоплением людей
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                   <v-card
                    class="pa-2 d-flex justify-center align-center mt-10"
                    to="/theme1"
                    hover
                    flat
                    >
                    <div class="d-flex flex-column justify-center align-center">
                      <v-icon color="red" size="100">mdi-school</v-icon>
                       <v-card-text class="title text-center">
                        Действия работников при пожарах в учреждениях
                      </v-card-text>
                    </div>
                    </v-card>
                     <v-container>
                      <v-row>
                        <v-col cols="4">
                          <v-card
                          class="pa-2 d-flex justify-center align-center"
                          full-width
                          height="200"
                          to="/theme4"
                          :disabled="theme4Status"
                          hover
                          >
                            <v-img
                              lazy-src="img/material/fire-extinguisher.svg"
                              max-width="80"
                              src="img/material/fire-extinguisher.svg"
                              class="ma-1"
                            ></v-img>
                            <v-spacer></v-spacer>
                            <v-card-text class="subtitle-1 font-weight-light">
                              Первичные средства пожаротушения, автоматические установки пожарной сигнализации и пожаротушения
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                          class="pa-2 d-flex justify-center align-center"
                          full-width
                          height="200"
                          to="/theme5"
                          :disabled="theme5Status"
                          hover
                          >
                            <v-img
                              lazy-src="img/material/fireman.svg"
                              max-width="80"
                              src="img/material/fireman.svg"
                              class="ma-1"
                            ></v-img>
                            <v-spacer></v-spacer>
                            <v-card-text class="subtitle-1 font-weight-light">
                              Действия при возникновении пожара, вызов пожарной охраны
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                          class="pa-2 d-flex justify-center align-center"
                          full-width
                          height="200"
                          to="theme6"
                          :disabled="theme6Status"
                          hover
                          >
                            <v-img
                              lazy-src="img/material/shield.svg"
                              max-width="80"
                              src="img/material/shield.svg"
                              class="ma-1"
                            ></v-img>
                            <v-spacer></v-spacer>
                            <v-card-text class="subtitle-1 font-weight-light">
                              Оказание первой помощи пострадавшим при пожаре
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                </v-tab-item>
              </v-tabs-items>
              <!-- <v-container>
                <v-row>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Раздел №1</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/theme1"
                    hover
                    >

                      <v-img
                        lazy-src="img/material/balance.svg"
                        max-width="80"
                        src="img/material/balance.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="subtitle-1 font-weight-light">
                        Законодательная база в области пожарной безопасности
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №2</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/theme2"
                    hover
                    >
                      <v-img
                        lazy-src="img/material/human.svg"
                        max-width="80"
                        src="img/material/human.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="subtitle-1 font-weight-light">
                        Организационные мероприятия по обеспечению ПБ в зданиях и помещениях с массовым пребыванием людей
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №3</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/theme3"
                    hover
                    >
                      <v-img
                        lazy-src="img/material/hotel-2.svg"
                        max-width="80"
                        src="img/material/hotel-2.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="subtitle-1 font-weight-light">
                        Меры ПБ в зданиях и помещениях с массовым скоплением людей
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №4</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/theme4"
                    hover
                    >
                      <v-img
                        lazy-src="img/material/fire-extinguisher.svg"
                        max-width="80"
                        src="img/material/fire-extinguisher.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="subtitle-1 font-weight-light">
                        Первичные средства пожаротушения, автоматические установки пожарной сигнализации и пожаротушения
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №5</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/theme5"
                    hover
                    >
                      <v-img
                        lazy-src="img/material/fireman.svg"
                        max-width="80"
                        src="img/material/fireman.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="subtitle-1 font-weight-light">
                        Действия при возникновении пожара, вызов пожарной охраны
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №6</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/theme6"
                    hover
                    >
                      <v-img
                        lazy-src="img/material/shield.svg"
                        max-width="80"
                        src="img/material/shield.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="subtitle-1 font-weight-light">
                        Оказание первой помощи пострадавшим при пожаре
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container> -->
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-card-title class="font-weight-light">
                      Материалы для изучения и скачивания
                    </v-card-title>
                  </v-col>
                  <v-col cols="4">
                    <v-dialog
                      v-model="docsDash"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">

                      <v-card
                        class="pa-2 d-flex justify-center align-center"
                        full-width
                        height="200"
                        @click="docsDash"
                        v-bind="attrs"
                        v-on="on"
                        hover
                      >

                          <v-img
                            lazy-src="img/material/balance.svg"
                            max-width="80"
                            src="img/material/balance.svg"
                            class="ma-5"
                          ></v-img>
                          <v-spacer></v-spacer>
                          <v-card-text class="subtitle-1 font-weight-light">
                            Нормативно-правовые документы
                          </v-card-text>
                        </v-card>
                      </template>
                      <v-card>
                        <v-toolbar
                          dark
                          color="red"
                        >
                          <v-toolbar-title>Дополнительные материалы для скачивания</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-toolbar-items>
                            <v-btn
                              dark
                              text
                              @click="docsDash = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-toolbar-items>
                        </v-toolbar>
                        <DocsDash></DocsDash>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <!-- <v-col cols="4">

                    <v-dialog
                      v-model="membsDash"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          class="pa-2 d-flex justify-center align-center"
                          full-width
                          height="200"
                          @click="membsDash"
                          v-bind="attrs"
                          v-on="on"
                          hover
                        >
                          <v-img
                            lazy-src="img/material/document-shield.svg"
                            max-width="80"
                            src="img/material/document-shield.svg"
                            class="ma-5"
                          ></v-img>
                          <v-spacer></v-spacer>
                          <v-card-text class="subtitle-1 font-weight-light">
                            Памятки по материалам курса
                          </v-card-text>
                        </v-card>
                      </template>
                      <v-card>
                        <v-toolbar
                          dark
                          color="red"
                        >
                          <v-toolbar-title>Памятки по материалам курса</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-toolbar-items>
                            <v-btn
                              dark
                              text
                              @click="membsDash = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-toolbar-items>
                        </v-toolbar>
                        <MembsDash></MembsDash>
                      </v-card>
                    </v-dialog>
                  </v-col> -->
                  <v-col cols="4">

                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="80%"
                    >
                      <template v-slot:activator="{ on}">
                      <v-card
                        class="pa-2 d-flex justify-center align-center"
                        full-width
                        height="200"
                        v-on="on"
                        hover
                        >
                          <v-img
                            lazy-src="img/material/vocabulary.svg"
                            max-width="80"
                            src="img/material/vocabulary.svg"
                            class="ma-5"
                          ></v-img>
                          <v-spacer></v-spacer>
                          <v-card-text class="subtitle-1 font-weight-light">
                            Глоссарий
                          </v-card-text>
                        </v-card>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card flat>
                          <v-card-title dark>
                            Глоссарий
                          </v-card-title>
                          <v-card-text>
                            <Glossary></Glossary>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-btn
                              text
                              class="red"
                              dark
                              outlined
                              @click="dialog.value = false"
                            >Закрыть
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
                        <v-tab-item>
              <v-container>
                <!-- <v-row>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №1</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/quiz1"
                    hover
                    >
                      <div class="d-flex flex-column">
                        <v-card-text class="subtitle-1 font-weight-light text-center">
                           <span class="font-weight-bold">Правильных ответов в тесте: </span>
                              {{ getResQuiz1() }} / {{ allQuestionsQuiz1.length }}

                          <v-progress-linear
                            :color="colorLineQuiz1"
                            rounded
                            v-model="progressQuiz1"
                            class="mt-2"
                          ></v-progress-linear>
                        </v-card-text>
                      </div>

                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №2</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/quiz2"
                    hover
                    >
                      <div class="d-flex flex-column">
                        <v-card-text class="subtitle-1 font-weight-light text-center">
                           <span class="font-weight-bold">Правильных ответов в тесте: </span>
                              {{ getResQuiz2() }} / {{ allQuestionsQuiz2.length }}

                          <v-progress-linear
                            :color="colorLineQuiz2"
                            rounded
                            v-model="progressQuiz2"
                            class="mt-2"
                          ></v-progress-linear>
                        </v-card-text>
                      </div>

                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №3</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/quiz3"
                    hover
                    >
                      <div class="d-flex flex-column">
                        <v-card-text class="subtitle-1 font-weight-light text-center">
                           <span class="font-weight-bold">Правильных ответов в тесте: </span>
                              {{ getResQuiz3() }} / {{ allQuestionsQuiz3.length }}

                          <v-progress-linear
                            :color="colorLineQuiz3"
                            rounded
                            v-model="progressQuiz3"
                            class="mt-2"
                          ></v-progress-linear>
                        </v-card-text>
                      </div>

                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №4</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/quiz4"
                    hover
                    >
                      <div class="d-flex flex-column">
                        <v-card-text class="subtitle-1 font-weight-light text-center">
                           <span class="font-weight-bold">Правильных ответов в тесте: </span>
                              {{ getResQuiz4() }} / {{ allQuestionsQuiz4.length }}

                          <v-progress-linear
                            :color="colorLineQuiz4"
                            rounded
                            v-model="progressQuiz4"
                            class="mt-2"
                          ></v-progress-linear>
                        </v-card-text>
                      </div>

                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №5</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/quiz5"
                    hover
                    >
                      <div class="d-flex flex-column">
                        <v-card-text class="subtitle-1 font-weight-light text-center">
                           <span class="font-weight-bold">Правильных ответов в тесте: </span>
                              {{ getResQuiz5() }} / {{ allQuestionsQuiz5.length }}

                          <v-progress-linear
                            :color="colorLineQuiz5"
                            rounded
                            v-model="progressQuiz5"
                            class="mt-2"
                          ></v-progress-linear>
                        </v-card-text>
                      </div>

                    </v-card>
                  </v-col>
                  <v-col cols="4">
                     <v-card-text class="title font-weight-light">Тема №6</v-card-text>
                    <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    height="200"
                    to="/quiz6"
                    hover
                    >
                      <div class="d-flex flex-column">
                        <v-card-text class="subtitle-1 font-weight-light text-center">
                           <span class="font-weight-bold">Правильных ответов в тесте: </span>
                              {{ getResQuiz6() }} / {{ allQuestionsQuiz6.length }}

                          <v-progress-linear
                            :color="colorLineQuiz6"
                            rounded
                            v-model="progressQuiz6"
                            class="mt-2"
                          ></v-progress-linear>
                        </v-card-text>
                      </div>

                    </v-card>
                  </v-col>
                </v-row> -->
                <Stat></Stat>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <Hint></Hint>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Glossary from '@/components/Glossary.vue'
// import MembsDash from '@/components/Membs.vue'
import Stat from '@/components/Stat.vue'
import DocsDash from '@/components/Docs.vue'
import Hint from '@/components/Hint.vue'
export default {
  computed: {
    ...mapGetters([
      'allQuestionsQuiz1',
      'allQuestionsQuiz2',
      'allQuestionsQuiz3',
      'allQuestionsQuiz4',
      'allQuestionsQuiz5',
      'allQuestionsQuiz6'
    ])
  },
  data: () => ({
    pageProgressTheme1: [],
    pageProgressTheme2: [],
    pageProgressTheme3: [],
    pageProgressTheme4: [],
    pageProgressTheme5: [],
    pageProgressTheme6: [],
    theme1Status: false,
    theme2Status: true,
    theme3Status: true,
    theme4Status: true,
    theme5Status: true,
    theme6Status: true,
    tab: null,
    page1: false,
    progressQuiz1: 0,
    colorLineQuiz1: 'red',
    lenghtQuiz1: 10,
    progressQuiz2: 0,
    colorLineQuiz2: 'red',
    lenghtQuiz2: 10,
    progressQuiz3: 0,
    colorLineQuiz3: 'red',
    lenghtQuiz3: 10,
    progressQuiz4: 0,
    colorLineQuiz4: 'red',
    lenghtQuiz4: 10,
    progressQuiz5: 0,
    colorLineQuiz5: 'red',
    lenghtQuiz5: 5,
    progressQuiz6: 0,
    colorLineQuiz6: 'red',
    lenghtQuiz6: 5,
    glossary: false,
    membsDash: false,
    docsDash: false,
    tabsThemes: null

  }),
  methods: {
    getResQuiz1: function () {
      return localStorage.resultQuiz1
    },
    getResQuiz2: function () {
      return localStorage.resultQuiz2
    },
    getResQuiz3: function () {
      return localStorage.resultQuiz3
    },
    getResQuiz4: function () {
      return localStorage.resultQuiz4
    },
    getResQuiz5: function () {
      return localStorage.resultQuiz5
    },
    getResQuiz6: function () {
      return localStorage.resultQuiz6
    },
    // getProgressQuiz1: function () {
    //   this.progressQuiz1 = 10
    // },
    // progressPages1: function () {
    //   return this.pageProgressTheme1.length
    // },
    // progressPages2: function () {
    //   return this.pageProgressTheme2.length
    // },
    // progressPages3: function () {
    //   return this.pageProgressTheme3.length
    // },
    // setProgressPage1: function () {
    //   this.pageProgressTheme1.push(localStorage.page1)
    // },
    // setProgressPage2: function () {
    //   this.pageProgressTheme1.push(localStorage.page2)
    // },
    // setProgressPage3: function () {
    //   this.pageProgressTheme1.push(localStorage.page3)
    // }
    realProcent: function (lenghtQuiz) {
      return lenghtQuiz / 100
    }
  },
  watch: {
    progressQuiz2: function (newValue, oldValue) {
      if (newValue >= 70) {
        this.theme2Status = false
      }
    },
    progressQuiz3: function (newValue, oldValue) {
      if (newValue >= 70) {
        this.theme3Status = false
      }
    },
    progressQuiz4: function (newValue, oldValue) {
      if (newValue >= 70) {
        this.theme4Status = false
      }
    },
    progressQuiz5: function (newValue, oldValue) {
      if (newValue >= 70) {
        this.theme5Status = false
      }
    },
    progressQuiz6: function (newValue, oldValue) {
      if (newValue >= 70) {
        this.theme6Status = false
      }
    }
  },
  mounted () {
    this.progressQuiz1 = ((this.getResQuiz1() * 100) / this.lenghtQuiz1)
    this.progressQuiz2 = ((this.getResQuiz2() * 100) / this.lenghtQuiz2)
    this.progressQuiz3 = ((this.getResQuiz3() * 100) / this.lenghtQuiz3)
    this.progressQuiz4 = ((this.getResQuiz4() * 100) / this.lenghtQuiz4)
    this.progressQuiz5 = ((this.getResQuiz5() * 100) / this.lenghtQuiz5)
    this.progressQuiz6 = ((this.getResQuiz6() * 100) / this.lenghtQuiz6)
    // console.log(this.progressQuiz6)
    if (this.progressQuiz1 > 70) {
      this.colorLineQuiz1 = 'warning'
    }
    if (this.progressQuiz1 > 80) {
      this.colorLineQuiz1 = 'green'
    }
    if (this.progressQuiz2 > 70) {
      this.colorLineQuiz2 = 'warning'
    }
    if (this.progressQuiz2 > 80) {
      this.colorLineQuiz2 = 'green'
    }
    if (this.progressQuiz3 > 70) {
      this.colorLineQuiz3 = 'warning'
    }
    if (this.progressQuiz3 >= 80) {
      this.colorLineQuiz3 = 'green'
    }
    if (this.progressQuiz4 > 70) {
      this.colorLineQuiz4 = 'warning'
    }
    if (this.progressQuiz4 >= 80) {
      this.colorLineQuiz4 = 'green'
    }
    if (this.progressQuiz5 > 70) {
      this.colorLineQuiz5 = 'warning'
    }
    if (this.progressQuiz5 >= 80) {
      this.colorLineQuiz5 = 'green'
    }
    if (this.progressQuiz6 > 70) {
      this.colorLineQuiz6 = 'warning'
    }
    if (this.progressQuiz6 >= 80) {
      this.colorLineQuiz6 = 'green'
    }
  },
  components: {
    Glossary,
    // MembsDash,
    DocsDash,
    Hint,
    Stat
  }
}
</script>
<style lang="less">
.ptm-dash-tab {
  background-color: #FFEBEE;
}
.ptm-glossary {
  background-color: #FFEBEE;
}
</style>
